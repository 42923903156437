<template>
  <div v-if="number<=28" class="addimagewith">
  <v-dialog
      v-model="showimage"
      max-width="500"
  >
    <v-img contain :src="$store.state.upload + image" width="100%" max-height="500px" style="background-color: grey">
      <template v-slot:default>
        <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
          <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
        </svg>
      </template>

    </v-img>
  </v-dialog>

  <div style="width: 100%">
    <v-progress-linear
        v-if="imageprogress"
        :value="imageprogress"
    >
    </v-progress-linear>
    <div class="d-flex justify-space-between align-center" >
    <div class="upbox" style="width: 100%">
      <div class="d-flex">
      <svg v-if="image" :class="{'animate__animated animate__flash':serviceuploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
      </svg>
      <p v-if="image" class="d-flex align-center ma-0 primary--text" style="font-size: 14px;" >تصویر ({{number+2}}) <img @click="showimage=true" class="mr-2" style="cursor: pointer" height="35" :src="$store.state.smallthumbnail + this.image"/></p>
      <p v-else class="ma-0" style="font-size: 14px;" >تصویر ({{number+2}})</p>

      </div>


      <v-btn
          height="32"
          style="font-size: 10px !important;"
          class="btn my-1 mr-auto px-2"
      >
        <template v-slot:default>

          <label style="cursor: pointer ; height: 32px;display: flex;align-items: center">
            <input type="file" ref="workimagefile" @change="uploadserviceimage" accept="image/*" style="display: none">
            انتخاب و آپلود فایل
          </label>

        </template>
      </v-btn>


    </div>
      <div @click="deleteItem" style="cursor: pointer" >
    <v-icon v-if="image" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
      </div>
    </div>
  </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  props : {
    number : null,
    loadedimage : null,
  },
  data() {
    return {
      image : this.loadedimage[this.number],
      imageformat:null,
      serviceuploading :false,
      imageprogress : 0,
      showimage : false,
      uploaded : false,
    }
  },
  watch : {
    loadedimage(){
      this.image = this.loadedimage[this.number];
    }
  },
  methods : {
    deleteItem(){
     /* let del = [this.number,this.image];*/
      this.$emit('deleteitem',this.number);
      /*this.image = '';*/
    },
    /*deleteFillItem(){
      this.$emit('deleteitem',this.image);
    },*/
    uploadserviceimage(){
      this.image = '';
      this.workimagefile = this.$refs.workimagefile.files[0];
      let formData = new FormData();
      formData.append('file', this.workimagefile);
      axios.post(this.$store.state.api+'imageupload?width=&height=&tw=600&th=400&stw=200&sth=200', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.imageprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.serviceuploading=true;
                this.image = response.data.data;
                let add = [this.number,this.image];
                this.$emit('addimage',add);
              this.imageprogress = 0;
            }
          }
      ).catch(()=>{
        this.imageprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    }
  },
};
</script>
