<template>
  <div>
    <v-dialog
        v-model="showimage"
        max-width="500"
    >
      <v-img contain :src="$store.state.upload + image" width="100%" max-height="500px" style="background-color: grey">
        <template v-slot:default>
          <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
            <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
          </svg>
        </template>

      </v-img>
    </v-dialog>
    <div ref="workedit" ></div>



    <v-text-field
        background-color="white"
        label="عنوان محصول یا خدمت"
        outlined
        v-model="title"
        :disabled="unpaid"
        height="50"
        style="font-size: 16.4px !important;"
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <div class="d-flex justify-space-between flex-wrap flex-md-nowrap">

    <div class="workadsfield">
    <v-text-field
        background-color="white"
        label="شماره تماس"
        outlined
        v-model="tell"
        :disabled="unpaid"
        height="50"
        style="font-size: 16.4px !important;"
        color="#570654"
        class="rounded-0 "
    ></v-text-field>
    </div>


    <div class="workadsfield">
    <v-text-field
        background-color="white"
        label="قیمت"
        outlined
        v-model="price"
        :disabled="unpaid"
        height="50"
        style="font-size: 16.4px !important;"
        color="#570654"
        class="rounded-0"
    >
      <template v-slot:append>
        <p class="ma-0" style="margin-top: 2px !important;">تومان</p>
      </template>
    </v-text-field>
    </div>

    <div class="editwork workadsfield">

    <v-select
        label="انتخاب زمان"
        solo
        :disabled="unpaid"
        v-model="selectedtime"
        :items="selecttimes"
        item-value="days"
        item-text="title"
        height="56"
        class="rounded-0"
    >
      <template v-slot:append>
        <img src="../../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
      </template>
      <template v-slot:no-data>
        <div class="d-flex justify-center">
          <p class="ma-0 my-2" style="font-size: 14px;user-select: none">در حال بارگزاری</p>
        </div>
      </template>
    </v-select>

    </div>

    </div>

    <v-textarea
        background-color="white"
        label="توضیحات"
        outlined
        v-model="desc"
        auto-grow
        :disabled="unpaid"
        style="font-size: 16.4px !important;"
        rows="4"
        color="#570654"
        class="rounded-0 "
    ></v-textarea>

    <div class="seclectemploy d-flex justify-end" >
      <v-checkbox
          v-model="employ"
          label="آگهی استخدام"
          :disabled="unpaid"
          color="primary"
          :value="1"
      ></v-checkbox>
    </div>

    <div class="d-flex flex-wrap justify-space-between align-center">

      <div class="addimagewith">
        <v-progress-linear
            v-if="imageprogress"
            :value="imageprogress"
        >
        </v-progress-linear>
        <div class="d-flex align-center" >
        <div class="upbox" style="width: 100%">
          <svg v-if="image" :class="{'animate__animated animate__flash':serviceuploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
          </svg>
          <p v-if="image" class="d-flex align-center ma-0 primary--text" style="font-size: 14px;" >تصویر<img @click="showimage=true" class="mr-2" style="cursor: pointer" height="35" :src="$store.state.smallthumbnail + this.image"/></p>
          <p v-else class="ma-0" style="font-size: 14px;" >تصویر شاخص</p>


          <v-btn
              height="32"
              style="font-size: 10px !important;"
              :disabled="unpaid"
              class="btn my-1 mr-auto px-2"
          >
            <template v-slot:default>

              <label style="cursor: pointer ; height: 32px;display: flex;align-items: center">
                <input type="file" ref="workimagefile" @change="uploadserviceimage" accept="image/*" style="display: none">
                انتخاب و آپلود فایل
              </label>

            </template>
          </v-btn>
        </div>
          <span @click="image = null" style="cursor: pointer" >
      <v-icon v-if="image" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
        </span>
        </div>




      </div>

      <v-btn
          v-if="status==='add'"
          class="btn  mx-1 mr-auto my-sm-1 my-3"
          @click="addToService"
          :disabled="unpaid"
          :loading="updateloading"
      >
        افزودن به لیست
      </v-btn>
      <div v-if="status==='edit'" class="mr-auto">
        <v-btn
            class="btn  ml-1 mx-auto my-sm-1 my-3"
            @click="cancel"
            :disabled="unpaid"
        >
          انصراف
        </v-btn>

        <v-btn
            class="btn  mx-1 mx-auto my-sm-1 my-3"
            @click="updateService"
            :loading="updateloading"
            :disabled="unpaid"
        >
          ذخیره و بروزرسانی
        </v-btn>
      </div>
    </div>

    <div v-if="unpaid">
    <p style="margin-right: 140px;margin-top: 20px;font-size: 30px;user-select: none" class="mb-0 primary--text" >+</p>
    </div>
    <div v-else  style="margin-right: 115px;">
    <v-icon style="cursor: pointer;font-size: 30px;user-select: none" @click="pushtoArray" class="mb-0 primary--text" >mdi-plus</v-icon>
      <v-icon v-if="images.length>0" style="cursor: pointer;font-size: 30px;user-select: none" @click="images.pop()" class="mb-0 primary--text" >mdi-minus</v-icon>
    </div>



    <div class="d-flex flex-wrap justify-space-between">
      <AddImage v-for="(n,i) in images" :key="i" :number="i" :loadedimage="images" class="mb-1" @deleteitem="deleteItem($event)" @addimage="addItem($event)"></AddImage>
    </div>



    <div v-if="items.length>0 & !unpaid ">
    <v-divider class="my-6"></v-divider>
    <p class="mr-2" style="font-size: 14px" >لیستی از آگهی های شما موجود است. جهت انجام عملیات بر روی گزینه مربوط به آن کلیک نمائید.</p>


    <table>
      <tr >
        <th style="width: 50%">عنوان</th>
        <th class="text-center px-0">وضعیت</th>
        <th class="text-center px-0" style="width: 5%"><span class="d-sm-block d-none">زمان انتشار</span><span class="d-block d-sm-none">ز.انتشار</span></th>
        <th class="text-center px-0">ویرایش</th>
        <th class="text-center px-1">حذف</th>
        <th class="px-0"><div style="min-height: 42px" ></div></th>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td style="width: 50%" > <v-img style="float: right;margin-left: 5px" :src="$store.state.smallthumbnail + item.image" width="32">
          <template v-slot:default>
            <div style="width: 100%;height: 32px;padding-top: 5px; background-color: rgba(0,0,0,0.5)" class="d-flex justify-center">
              <span v-if="item.image" style="color:#fff;" >{{item.images.length + 1}}</span>
              <span v-else style="color:#fff;" >{{item.images.length}}</span>
            </div>
          </template>
        </v-img> {{ item.title }}</td>
        <td class="text-center px-0">منتشر شده</td>
        <th class="text-center px-1">{{item.createTime | momentHour}}</th>
        <td style="cursor: pointer" @click="sendtotextfields(item.id,item.title,item.desc,item.image,item.images,item.price,item.tell,item.employ)" class="text-center px-0"><img src="../../assets/image/Profile/edit.png" height="16" width="16"/></td>
        <td class="text-center px-0"><img src="../../assets/image/Profile/delete.png" height="16" width="16"/></td>
        <td class="px-0"> <div style="min-height: 42px" ></div> </td>
      </tr>
    </table>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import {momentfilter} from "@/plugins/moment";
import AddImage from "./WorkAds/AddImage";
import numeral from 'numeral';

export default {
  name: 'App',
  components : {
    AddImage
  },
  mixins : [momentfilter],
  props : {
    catId : null,
    unpaid : {
      default : true
    },
  },
  data() {
    return {
      title : '',
      desc : '',
      selectedId : null,
      items : [],
      image : '',
      imageformat:null,
      serviceuploading :false,
      imageprogress : 0,
      status : 'add',
      updateloading : false,
      showimage : false,
      images : [],
      price : null,
      paramprice : null,
      selectedtime : 15,
      tell : null,
      employ : null,
      selecttimes : [
        {
          title : '15 روزه',
          days : 15
        },
        {
          title : 'یک ماهه',
          days : 30
        },
        {
          title : 'دو ماهه',
          days : 60
        },
        {
          title : 'شش ماهه',
          days : 180
        },
        {
          title : 'یک ساله',
          days : 365
        },
      ]
    }
  },
  watch : {
    image(newval,oldval){
      if(newval!=oldval){
        this.imageformat = this.image.substring(this.image.length-4, this.image.length);
      }
    },
    price(newVal,oldVal){
      if(this.price){
        if(newVal!==oldVal){
          this.price = numeral(newVal).format('0,0');
          this.paramprice = numeral(this.price).value();
        }
      }
    },
  },
  created() {
    axios.get(this.$store.state.api + 'workads', {
      params: {
        id : this.$store.state.userId,
      }
    }).then(response => {
      this.items = response.data.data;
    })
  },
  methods : {
    addItem(add){

      this.images[parseInt(add[0])] = add[1].toString();
      this.images = JSON.parse(JSON.stringify(this.images));
    },
    cleanEmtyValues(){
      this.images = this.images.filter(item=>item);
    },
    pushtoArray(){
      this.images.push("");
    },
    deleteItem(del){
      this.images[parseInt(del)] = "";
      this.images = JSON.parse(JSON.stringify(this.images));
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    addToService(){
      this.updateloading = true;
      this.cleanEmtyValues();
      axios.post(this.$store.state.api + 'addads', {
        workId : this.$store.state.userId,
        title : this.title,
        desc : this.desc,
        image : this.image,
        images : this.images,
        price : this.paramprice,
        expireTime : this.selectedtime,
        tell : this.tell,
        employ: this.employ,
      }).then(response => {
        if(response.data.result){
          axios.get(this.$store.state.api + 'workads', {
            params: {
              id : this.$store.state.userId,
            }
          }).then(response => {
            this.items = response.data.data;
          })
          this.cancel();
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'به لیست افزوده شد';
          this.updateloading = false;
        }
      })
    },
    updateService(){
      this.updateloading = true;
      this.cleanEmtyValues();
      axios.post(this.$store.state.api + 'setads', {
        id : this.selectedId,
        title : this.title,
        desc : this.desc,
        image : this.image,
        images : this.images,
        price : this.paramprice,
        expireTime : this.selectedtime,
        tell : this.tell,
        employ: this.employ,
      }).then(response => {
        if(response.data.result){
          axios.get(this.$store.state.api + 'workads', {
            params: {
              id : this.$store.state.userId,
            }
          }).then(response => {
            this.items = response.data.data;
          })
          this.cancel();
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'ویرایش انجام شد';
          this.updateloading = false;
        }
      })
    },
    sendtotextfields(id,title,desc,image,images,price,tell,employ){
      this.goto('workedit');
      this.selectedId = id;
      this.title = title;
      this.desc = desc;
      this.image = image;
      this.images = images;
      this.price = price;
      this.tell = tell;
      this.status = 'edit';
      this.employ = parseInt(employ);

    },
    cancel(){

      this.title = '';
      this.desc = '';
      this.image = '';
      this.status = 'add';
      this.price = null;
      this.paramprice = null;
      this.tell = null;
      this.images = [];
      this.selectedId = null;
      this.selectedtime = 15;
      this.employ = null;

    },
    uploadserviceimage(){
      this.image = '';
      this.workimagefile = this.$refs.workimagefile.files[0];
      let formData = new FormData();
      formData.append('file', this.workimagefile);
      axios.post(this.$store.state.api+'imageupload?width=&height=&tw=600&th=400&stw=200&sth=200', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.imageprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.serviceuploading=true;
              this.image = response.data.data;
              this.imageprogress = 0;
            }
          }
      ).catch(()=>{
        this.imageprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    }
  }
};
</script>


<style lang="scss">


.seclectemploy * {
  color: #570654 !important;
}

.seclectemploy .v-input--selection-controls{
  margin-top: 0px !important;
  margin-bottom: 4px !important;
  padding-top: 0px !important;
}


.workadsfield{
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .workadsfield{
    width: 200px;
  }


}

.addimagewith{
  width: 100%;
}

@media only screen and (min-width: 715px){
  .addimagewith{
    width: 300px;
  }
}

</style>

