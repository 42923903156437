<template>
  <div class="editwork">

    <p class="mb-2" style="font-size: 14px">نام کاربری :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        :rules="[usernameRules.pattern]"
        :error-messages="usernameErrors"
        v-model="username"
        @input="$v.username.$touch()"
        @blur="$v.username.$touch()"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <p class="mb-2" style="font-size: 14px">شماره همراه کاربری :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        :error-messages="tellErrors"
        @input="$v.tell.$touch()"
        @blur="$v.tell.$touch()"
        :rules="[ tellRules.pattern]"
        v-model="tell"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <p class="mb-2" style="font-size: 14px">ایمیل کاربری :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        style="direction: ltr"
        :error-messages="emailErrors"
        v-model="email"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <p class="mb-2" style="font-size: 14px">پسورد قبلی :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        v-model="oldPassword"
        :type="passshow3 ? 'text' : 'password'"
        solo
        color="#570654"
        class="rounded-0 "
    >
      <template v-slot:append>
        <div style="cursor: pointer;margin-top: 3px" @click="passshow3= !passshow3" >
          <img v-if="passshow3" src="../../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
          <img v-else src="../../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
        </div>
      </template>
    </v-text-field>

    <p class="mb-2" style="font-size: 14px">پسورد جدید :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        :error-messages="passErrors"
        v-model="password"
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
        :type="passshow ? 'text' : 'password'"
        solo
        color="#570654"
        class="rounded-0 "
    >
      <template v-slot:append>
        <div style="cursor: pointer;margin-top: 3px" @click="passshow= !passshow" >
          <img v-if="passshow" src="../../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
          <img v-else src="../../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
        </div>
      </template>
    </v-text-field>

    <p class="mb-2" style="font-size: 14px">تکرار پسورد جدید :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        v-model="repeatPassword"
        :error-messages="samepassErrors"
        @input="$v.repeatPassword.$touch()"
        @blur="$v.repeatPassword.$touch()"
        :type="passshow2 ? 'text' : 'password'"
        solo
        color="#570654"
        class="rounded-0 "
    >
      <template v-slot:append>
        <div style="cursor: pointer;margin-top: 3px" @click="passshow2= !passshow2" >
          <img v-if="passshow2" src="../../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
          <img v-else src="../../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
        </div>
      </template>
    </v-text-field>

    <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>
    <v-btn
        class="btn d-block mr-auto mb-1 ml-1"
        @click="changes"
        :disabled="unloaded"
        :loading="updateloading"
    >
      ذخیره و بروزرسانی
    </v-btn>

  </div>
</template>

<script>

import axios from "axios";
import { validationMixin } from 'vuelidate'
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  name: 'App',
  mixins : [validationMixin],
  props : {
    dusername : null,
    dtell : null,
    demail : null,
    unloaded : {
      default : true,
    }
  },
  watch : {
    dusername(){
      this.username = this.dusername;
    },
    doldpassword(){
      this.oldPassword = this.doldpassword;
    },
    dtell(){
      this.tell = this.dtell;
    },
    demail(){
      this.email = this.demail;
    }

  },
  data() {
    return {

      updateloading : false,
      updatenotice : false,


      username : null,
      password : null,
      repeatPassword : null,
      oldPassword : null,
      tell : null,
      email : null,

      loading : false,

      usernameRules: {
        pattern: value => {
          const pattern = /^(?=.{6,20}$)(?![_.0-9])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/
          return pattern.test(value) || 'باید حاوی حروف مجاز و حداقل 6 کاراکتر باشد'
        },
      },

      tellRules: {
        pattern: value => {
          const pattern = /^(\+98|0)?9\d{9}$/
          return pattern.test(value) || 'شماره تماس نا معتبر است'
        },
      },

      passshow: false,
      passshow2: false,
      passshow3: false,

    }
  },
  validations: {
    password: {
      minLength: minLength(6)
    },

    email: {
      required,
      email
    },

    repeatPassword: {
      sameAsPassword: sameAs('password')
    },

    username:{
      required,
    },

    tell: {
      required,
    },

  },
  computed : {
    passErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('پسورد باید دارای حداقل 6 کاراکتر باشد')
      return errors
    },
    samepassErrors () {
      const errors = []
      if (!this.$v.repeatPassword.$dirty) return errors
      !this.$v.repeatPassword.sameAsPassword && errors.push('تکرار پسورد همخوانی ندارد')
      return errors
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required && errors.push('نام کاربری را وارد کنید')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('ایمیل را به شکل صحیح وارد کنید')
      !this.$v.email.required && errors.push('ایمیل را وارد کنید')
      return errors
    },
    tellErrors () {
      const errors = []
      if (!this.$v.tell.$dirty) return errors
      !this.$v.tell.required && errors.push('شماره تماس را وارد کنید')
      return errors
    },
    numberValidate() {
      return this.tellRules.pattern(this.tell) === true && this.usernameRules.pattern(this.username) === true;
    },
  },
  methods : {
    changes(){

      this.$v.$touch();
      if (this.$v.$invalid || !this.numberValidate) {
        console.log('Err');
      } else {
        this.updateloading = true;
        axios.post(this.$store.state.api + 'changeuserpass', {
          userId : this.$store.state.userId,
          tell : this.tell,
          username : this.username,
          oldPassword : this.oldPassword,
          password : this.password,
          email : this.email,
        }).then(response => {
          if (response.data.result) {
            this.loading = false;
            this.updateloading = false;
            this.$store.state.notify.is = true;
            this.$store.state.notify.text = 'اطلاعات کاربری با موفقیت ویرایش شد';
          }else {
            this.loading = false;
            this.updateloading = false;
            this.$store.state.notify.is = true;
            this.$store.state.notify.text = response.data.message;
          }
        });
      }
    }
  }
};
</script>


<style lang="scss" >
.editwork .error--text fieldset {
  border-color: #ff6969 !important;
}

.editwork .v-text-field .v-text-field__details *{
  font-size: 12px !important;
  color: #ff6969 !important;
}

.editwork .address .v-text-field .v-text-field__details *{
  font-size: 12px !important;
}


.map {
  border-radius: 2px;
  overflow: hidden;
  border: 5px #e2e2e2 solid;
  /*box-shadow: 0px 0px 10px #eee;*/
  width: 100%;
  height: 100%;
}


</style>