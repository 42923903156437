<template>
  <div class="editwork">
    <p class="mb-2" style="font-size: 14px">نام شرکت / مجموعه :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        :error-messages="titleErrors"
        v-model="title"
        @input="$v.title.$touch()"
        @blur="$v.title.$touch()"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <p class="mb-2" style="font-size: 14px">حوزه فعالیت :</p>
    <v-select
        label="انتخاب حوزه فعالیت"
        solo
        :disabled="unloaded"
        v-model="catId"
        :items="cats"
        item-text="title"
        item-value="id"
        height="52"
        class="rounded-0"
    >
      <template v-slot:append>
        <img src="../../assets/image/Cat/icon-down.png" height="10.47" width="18"/>
      </template>
    </v-select>

    <p class="mb-2" style="font-size: 14px">نام برند تجاری :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        :error-messages="brandErrors"
        v-model="brand"
        @input="$v.brand.$touch()"
        @blur="$v.brand.$touch()"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <div class="d-flex justify-space-between">
    <p class="mb-2" style="font-size: 14px">شماره تلفن ثابت :</p>

    <div>
      <v-icon v-if="otherFixedNumber.length>0" class="ml-1" @click="fieldMinus('fixed')">mdi-minus-circle-outline</v-icon>
      <v-icon @click="fieldPlus('fixed')" >mdi-plus-circle-outline</v-icon>
    </div>

    </div>


    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        style="direction: ltr"
        v-model="fixedNumber"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>


    <OtherFields :loadedfield="otherFixedNumber" v-for="(item,i) in otherFixedNumber" :key="i" :indexnumber="i" @fieldevent="fieldChange($event)" role="'fixed'" :fieldname="'تلفن ثابت'"></OtherFields>

    <div class="d-flex justify-space-between" >
    <p class="mb-2" style="font-size: 14px">شماره همراه : </p>
      <div>
        <v-icon v-if="otherTell.length>0" class="ml-1" @click="fieldMinus('tell')">mdi-minus-circle-outline</v-icon>
        <v-icon @click="fieldPlus('tell')" >mdi-plus-circle-outline</v-icon>
      </div>

    </div>

    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        v-model="coTell"
        solo
        style="direction: ltr"
        color="#570654"
        class="rounded-0 "
    ></v-text-field>

    <OtherFields :loadedfield="otherTell" v-for="(item,i) in otherTell" :key="i + 'tell'" :indexnumber="i" @fieldevent="fieldChange($event)" role="'tell'" :fieldname="'همراه'"></OtherFields>



    <div class="d-flex justify-space-between" >

    <p class="mb-2" style="font-size: 14px">شماره فکس : </p>

    <div>
      <v-icon v-if="otherFaxNumber.length>0" class="ml-1" @click="fieldMinus('fax')">mdi-minus-circle-outline</v-icon>
      <v-icon @click="fieldPlus('fax')" >mdi-plus-circle-outline</v-icon>
    </div>

    </div>

    <v-text-field
        background-color="white"
        height="50"
        outlined
        :disabled="unloaded"
        style="direction: ltr"
        v-model="faxNumber"
        solo
        color="#570654"
        class="rounded-0 "
    ></v-text-field>


    <OtherFields :loadedfield="otherFaxNumber" v-for="(item,i2) in otherFaxNumber" :key="i2+'fax'" @fieldevent="fieldChange($event)" :indexnumber="i2" :fieldname="'فکس'" role="'fax'"></OtherFields>

<!--    <p class="mb-2" style="font-size: 14px">ایمیل :</p>
    <v-text-field
        background-color="white"
        height="50"
        outlined
        v-model="email"
        solo
        color="#570654"
        class="rounded-0"
    ></v-text-field>-->

    <p class="mb-2" style="font-size: 14px">ایمیل شرکت :</p>
    <v-text-field
        background-color="white"
        height="50"
        v-model="coEmail"
        outlined
        :disabled="unloaded"
        style="direction: ltr"
        @input="$v.coEmail.$touch()"
        @blur="$v.coEmail.$touch()"
        :error-messages="companyemailErrors"
        solo
        color="#570654"
        class="rounded-0"
    ></v-text-field>

    <p class="mb-2" style="font-size: 14px">آدرس سایت :</p>

    <v-text-field
        background-color="white"
        height="50"
        :disabled="unloaded"
        outlined
        style="direction: ltr"
        v-model="siteUrl"
        solo
        color="#570654"
        class="rounded-0 "
    >
      <template v-slot:prepend-inner>
        <p class="ma-0" >https://</p>
      </template>
    </v-text-field>


    <div class="d-flex justify-space-between">
      <div style="width: 48%">
        <p class="mb-2" style="font-size: 14px">استان :</p>
        <v-select
            label="انتخاب استان"
            solo
            :disabled="unloaded"
            v-model="provinceId"
            :items="provinces"
            item-text="name"
            item-value="id"
            height="52"
            class="rounded-0"
        >
          <template v-slot:append>
            <img src="../../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
          </template>
        </v-select>
      </div>
      <div style="width: 48%">
        <p class="mb-2" style="font-size: 14px">شهر :</p>
        <v-select
            label="انتخاب شهر"
            solo
            :disabled="unloaded"
            v-model="cityId"
            :items="cities"
            item-text="name"
            item-value="id"
            height="52"
            class="rounded-0"
        >
          <template v-slot:append>
            <img src="../../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
          </template>
        </v-select>
      </div>

    </div>

    <p class="ma-0 mr-1" style="font-size: 12px">* مکان مورد نظر را در نقشه علامت گذاری کنید</p>
    <div v-if="!unloaded" style="height: 250px" class="pb-8 mt-2">
      <MapMarker :zoom="zoom" :coordinates="mapCenter" :marker-coordinates="mapCenter" @marker="setMarker($event)"></MapMarker>
    </div>

    <div class="d-flex justify-space-between">
    <p class="mb-2" style="font-size: 14px">آدرس / نشانی :</p>

      <div>
    <v-icon v-if="otherAddress.length>0" class="ml-1" @click="fieldMinus('address')">mdi-minus-circle-outline</v-icon>
    <v-icon @click="fieldPlus('address')" >mdi-plus-circle-outline</v-icon>
      </div>

    </div>

    <div class="address mb-2">
      <v-textarea
          background-color="white"
          outlined
          :disabled="unloaded"
          solo
          :error-messages="addressErrors"
          v-model="address"
          @input="$v.address.$touch()"
          @blur="$v.address.$touch()"
          color="#570654"
          class="rounded-0 "
      ></v-textarea>
    </div>



    <OtherFields :loadedfield="otherAddress" v-for="(item,i3) in otherAddress" :indexnumber="i3" @fieldevent="fieldChange($event)" :key="i3+'add'" :role="'address'"></OtherFields>

    <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>
    <v-btn
        class="btn d-block mr-auto mb-1 ml-1"
        @click="savework"
        :disabled="unloaded"
        :loading="updateloading"
    >
      ذخیره و بروزرسانی
    </v-btn>

  </div>
</template>

<script>
import MapMarker from "./EditWork/MapMarker";
import axios from "axios";
import { validationMixin } from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import OtherFields from "./EditWork/OtherFields";

export default {
  name: 'App',
  mixins : [validationMixin],
  components : {
    MapMarker,
    OtherFields
  },
  props : {
    dtitle : null,
    dcatId : null,
    dbrand : null,
    dfixedNumber : null,
    demail : null,
    dcoEmail : null,
    dcoTell : null,
    dfaxNumber : null,
    dsiteUrl : null,
    dprovinceId : null,
    dcityId : null,
    daddress : null,
    unloaded : {
      default : true,
    },
    dotheraddress : null,
    dotherfax : null,
    dotherfixed : null,
    dothertell : null,
    dmarkercenter : null,
  },
  data() {
    return {
      provinces : [],
      cities : [],
      cats : [],
      updateloading : false,
      updatenotice : false,

      title : null,
      catId : null,
      brand : null,
      fixedNumber : null,
      email : null,
      coEmail : null,
      coTell : null,
      faxNumber : null,
      siteUrl : null,
      provinceId : null,
      cityId : null,
      mapCenter : null,
      marker : [],
      zoom : 13,
      address : null,
      otherFaxNumber : [],
      otherFixedNumber : [],
      otherAddress : [],
      otherTell : [],
    }
  },
  validations: {
    address:{
      required
    },
    coEmail: {
      email,
      required
    },
    title : {
      required
    },
    brand : {
      required
    },

  },
  created() {

    axios.get(this.$store.state.api + 'getprovince').then(response => {
      this.provinces = response.data.data;
    })

    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;
    })

    axios.get(this.$store.state.api + 'getcity',{params : {id:this.provinceId}}).then(response => {
      this.cities = response.data.data;
    })

  },
  watch : {
    dtitle(){
      this.title = this.dtitle;
    },
    dcatId(){
      this.catId = this.dcatId;
    },
    dbrand(){
      this.brand = this.dbrand;
    },
    dfixedNumber(){
      this.fixedNumber = this.dfixedNumber;
    },
    demail(){
      this.email = this.demail;
    },
    dcoEmail(){
      this.coEmail = this.dcoEmail;
    },
    dcoTell(){
      this.coTell = this.dcoTell;
    },
    dfaxNumber(){
      this.faxNumber = this.dfaxNumber;
    },
    dsiteUrl(){
      this.siteUrl = this.dsiteUrl;
    },
    dprovinceId(){
      this.provinceId = this.dprovinceId;
    },
    dcityId(){
      this.cityId = this.dcityId;
    },
    daddress(){
      this.address = this.daddress;
    },
    dotheraddress(val){
      this.otherAddress = this.dotheraddress;
      if(val===null){
        this.otherAddress = [];
      }
    },
    dotherfax(val){
      this.otherFaxNumber = this.dotherfax;
      if(val===null){
        this.otherFaxNumber = [];
      }
    },
    dotherfixed(val){
      this.otherFixedNumber = this.dotherfixed;
      if(val===null){
        this.otherFixedNumber = [];
      }
    },
    dothertell(val){
      this.otherTell = this.dothertell;
      if(val===null){
        this.otherTell = [];
      }
    },
    dmarkercenter(val){
      this.mapCenter = this.dmarkercenter;
      if(val===null){
        this.mapCenter = [51.404343, 35.715298];
        this.zoom = 9;
      }
    },
    provinceId(newval , oldval){
      if(newval!==oldval){
        this.cities = [];
        axios.get(this.$store.state.api + 'getcity',{params : {id:this.provinceId}}).then(response => {
          this.cities = response.data.data;
        })
      }
    }
  },
  computed : {
    titleErrors () {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push('نام شرکت/ مجموعه را وارد کنید')
      return errors
    },
    brandErrors () {
      const errors = []
      if (!this.$v.brand.$dirty) return errors
      !this.$v.brand.required && errors.push('نام برند تجاری را وارد کنید')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      !this.$v.address.required && errors.push('آدرس را وارد کنید')
      return errors
    },
    companyemailErrors () {
      const errors = []
      if (!this.$v.coEmail.$dirty) return errors
      !this.$v.coEmail.email && errors.push('ایمیل را به شکل صحیح وارد کنید')
      !this.$v.coEmail.required && errors.push('ایمیل را وارد کنید')
      return errors
    },
  },
  methods : {
    cleanEmtyValues(){
      this.otherFixedNumber = this.otherFixedNumber.filter(item=>item);
      this.otherFaxNumber = this.otherFaxNumber.filter(item=>item);
      this.otherAddress = this.otherAddress.filter(item=>item);
      this.otherTell = this.otherTell.filter(item=>item);
    },
    fieldChange(data){
      if(data[0].toString()==="address"){
        this.otherAddress[parseInt(data[1])] = data[2].toString();
        this.otherAddress = JSON.parse(JSON.stringify(this.otherAddress));
      } else if (data[0].toString()==="'fax'"){
        this.otherFaxNumber[parseInt(data[1])] = data[2].toString();
        this.otherFaxNumber = JSON.parse(JSON.stringify(this.otherFaxNumber));
      } else if (data[0].toString()==="'fixed'"){
        this.otherFixedNumber[parseInt(data[1])] = data[2].toString();
        this.otherFixedNumber = JSON.parse(JSON.stringify(this.otherFixedNumber));
      } else {
        this.otherTell[parseInt(data[1])] = data[2].toString();
        this.otherTell = JSON.parse(JSON.stringify(this.otherTell));
      }
    },
    setMarker(mark){
      this.marker = mark;
    },
    fieldMinus(field){
      if(field==='address'){
        this.otherAddress.pop();
      } else if (field === 'fax') {
        this.otherFaxNumber.pop();
      } else if (field === 'fixed') {
        this.otherFixedNumber.pop();
      } else {
        this.otherTell.pop();
      }
    },
    fieldPlus(field){
      if(field==='address'){
        this.otherAddress.push('');
      } else if (field === 'fax') {
        this.otherFaxNumber.push('');
      } else if (field === 'fixed') {
        this.otherFixedNumber.push('');
      } else {
        this.otherTell.push('');
      }
    },
    savework() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('Err');
      } else {
      this.updateloading = true;
      this.cleanEmtyValues();
        let otherAdd,otherFax,otherFixed,otherTel = null;
      if(this.otherAddress.length>0){
        otherAdd = JSON.parse(JSON.stringify(this.otherAddress));
      } else {
        otherAdd = null;
      }

        if(this.otherFixedNumber.length>0){
          otherFixed = JSON.parse(JSON.stringify(this.otherFixedNumber)) ;
        } else {
          otherFixed = null;
        }

        if(this.otherFaxNumber.length>0){
          otherFax = JSON.parse(JSON.stringify(this.otherFaxNumber)) ;
        } else {
          otherFax = null;
        }

        if(this.otherTell.length>0){
          otherTel = JSON.parse(JSON.stringify(this.otherTell)) ;
        } else {
          otherTel = null;
        }


      axios.post(this.$store.state.api + 'setwork', {
        userId: this.$store.state.userId,
        title: this.title,
        catId: this.catId,
        brand: this.brand,
        fixedNumber: this.fixedNumber,
        faxNumber: this.faxNumber,
        coEmail: this.coEmail,
        coTell: this.coTell,
        role: "work",
        email: this.email,
        siteUrl: this.siteUrl,
        provinceId: this.provinceId,
        cityId: this.cityId,
        address: this.address,
        otherFixedNumber : otherFixed,
        otherFaxNumber : otherFax,
        otherAddress : otherAdd,
        otherTell : otherTel,
        long : this.marker[0].toString(),
        lat : this.marker[1].toString(),
      }).then(response => {
        if (response.data.result) {
          localStorage.setItem('title', (this.title));
          this.$store.state.userTitle = this.title;
          this.updatenotice = true;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'بروزرسانی شد';
          this.updateloading = false;
        }
      })
    }
    }
  }
};
</script>


<style lang="scss" >
.editwork .error--text fieldset {
  border-color: #ff6969 !important;
}

.editwork .v-text-field .v-text-field__details *{
  font-size: 12px !important;
  color: #ff6969 !important;
}

.editwork .address .v-text-field .v-text-field__details *{
  font-size: 12px !important;
}


.map {
  border-radius: 2px;
  overflow: hidden;
  border: 5px #e2e2e2 solid;
  /*box-shadow: 0px 0px 10px #eee;*/
  width: 100%;
  height: 100%;
}


</style>