<template>
  <div>
    <v-dialog
        v-model="showvideo"
        max-width="500"
        persistent
    >
      <div style="background: linear-gradient(to bottom, #2e2e2e, #333 50%, black 75%);">
        <svg @click="closevideo" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
          <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
        </svg>
      <video ref="video" v-if="video" width="100%" controls>
        <source :src="$store.state.upload + video" type="video/mp4">
        مرورگر شما ویدیو html را پشتیبانی نمی کند.
      </video>
      </div>
    </v-dialog>
    <div class="d-flex flex-wrap flex-sm-nowrap justify-space-between">
      <div class="order-1 order-sm-0 addimagewith" >
        <v-progress-linear
            v-if="videoprogress"
            :value="videoprogress"
        >
        </v-progress-linear>
        <div class="d-flex align-center" >
        <div class="upbox" style="width: 100%">
          <svg v-if="video" :class="{'animate__animated animate__flash':uploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
          </svg>
          <p v-if="video" @click="showvideo=true" class="ma-0 primary--text" style="font-size: 14px">معرفی<span style="font-size: 12px;cursor: pointer"> (Intro{{videoformat}}) </span></p>
          <p v-else class="ma-0" style="font-size: 14px" >ویدیو معرفی</p>


          <v-btn
              height="32"
              style="font-size: 10px !important;"
              :disabled="unloaded"
              class="btn my-1 mr-auto px-2"
          >
            <template v-slot:default>

              <label style="cursor: pointer ; height: 32px;display: flex;align-items: center">
                <input type="file" ref="videofile" @change="uploadvideo" accept="video/*" style="display: none">
                انتخاب و آپلود فایل
              </label>

            </template>
          </v-btn>


        </div>

          <span @click="video = null" style="cursor: pointer" >
      <v-icon v-if="video" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
        </span>

        </div>
        <p class="primary--text mb-0 mt-1" style="font-size: 11px">راهنما : حداکثر حجم فایل 50 مگابایت می باشد.</p>
        <p class="primary--text " style="font-size: 11px">پیشنهاد ما : آپلود ویدئوی مورد نظر در سایت آپارات و با استفاده از کد ویدئویی نمایش دهدید.</p>
      </div>
      <div class="abouttxt order-0 order-sm-1 videoaparat" style="margin-right: 5px" >
        <v-textarea
            background-color="white"
            label="کد ویدئویی (آی فریم) از سایت آپارات"
            outlined
            :value="videoUrl"
            v-model="videoUrl"
            auto-grow
            :disabled="unloaded"
            style="font-size: 12px !important;"
            rows="4"
            color="#570654"
            class="rounded-0 "
        ></v-textarea>
      </div>

    </div>

    <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>
    <v-btn
        class="btn d-block mr-auto mb-1 ml-1"
        @click="savevideo"
        :disabled="unloaded"
        :loading="updateloading"
    >
      ذخیره و بروزرسانی
    </v-btn>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  props : {
    dvideo : null,
    dvideoUrl : null,
    unloaded:{
      default : true,
    }
  },
  data() {
    return {
      videoprogress : 0,
      uploading : false,
      showvideo : false,
      updateloading : false,
      videoformat:null,
      updatenotice : false,

      video : null,
      videoUrl : null,
    }
  },
  watch : {

    dvideo(){
      this.video = this.dvideo;
    },

    dvideoUrl(){
      this.videoUrl = this.dvideoUrl;
    },

    video(newval,oldval){
      if(newval!=oldval){
        this.videoformat = this.video.substring(this.video.length-4, this.video.length);
      }
    },
  },
  methods : {
    closevideo(){
      this.$refs.video.pause();
      this.showvideo=false;
    },
    uploadvideo: function () {
      this.video = '';
      this.videofile = this.$refs.videofile.files[0];
      let formData = new FormData();
      formData.append('file', this.videofile);
      axios.post(this.$store.state.api+'fileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.videoprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.uploading=true;
              this.video = response.data.data;
              this.videoprogress = 0;
            }
          }
      ).catch(()=>{
        this.videoprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    },
    savevideo(){
      this.updateloading = true;
      axios.post(this.$store.state.api + 'setwork', {
        userId : this.$store.state.userId,
        video : this.video,
        videoUrl : this.videoUrl,
      }).then(response => {
        if(response.data.result){
          this.updatenotice = true;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'بروزرسانی شد';
          this.updateloading = false;
        }
      })
    }
  }
};
</script>


<style>

.videoaparat{
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .videoaparat{
    width: 350px;
  }
}

.addimagewith{
  width: 100%;
}

@media only screen and (min-width: 715px){
  .addimagewith{
    width: 300px;
  }
}

</style>
