<template>
  <mapir class="map" ref="map" :zoom="zoom" :apiKey="$store.state.mapApi" :center="coordinates" @click="markerOnClick">
    <mapAttributionControl/>
    <mapNavigationControl position="top-right"/>
    <mapGeolocateControl position="top-right"/>
    <mapMarker
        :coordinates.sync="marker"
        color="blue"
        :draggable="true"

    />
  </mapir>
</template>

<script>
import {
  mapir,
  mapMarker,
  mapNavigationControl,
  mapGeolocateControl,
  mapAttributionControl,
} from "mapir-vue";
export default {
  name: 'App',
  props : {
    zoom : null,
    coordinates : null,
    markerCoordinates : null,
  },
  data() {
    return {
      marker : null,
    }
  },
  created() {
    this.marker = this.markerCoordinates;
  },
  components : {
    mapir,
    mapMarker,
    mapAttributionControl,
    mapNavigationControl,
    mapGeolocateControl,
  },
  watch : {
    marker(){
      this.$emit('marker',this.marker);
    },
    markerCoordinates(){
      this.marker = this.markerCoordinates;
    },
  },methods : {
    markerOnClick(event){
      this.marker = [event.actualEvent.lngLat.lng,event.actualEvent.lngLat.lat];
    }
  }
};
</script>

<style lang="scss">
.v-application .blue{
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>

