<template>
  <div class="profile">
    <BreadCrumbs>خانه > حساب کاربری </BreadCrumbs>
    <v-container v-if="$vuetify.breakpoint.lg|| $vuetify.breakpoint.xl" class="d-flex flex-wrap flex-md-nowrap pa-0 mt-11">

      <div class="side-col order-1 order-md-0">
<!--        تصاویر -->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon9.png"/>
          <span slot="title" >بارگزاری تصاویر</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" >

            <UploadFiles :unloaded="unloaded" :dlogoimage="logoimage" :dbannerimage="bannerimage" :dpdf="pdf"></UploadFiles>

          </div>
        </ContantBlock>
<!--        ویرایش اطلاعات-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon2.png"/>
          <span slot="title" >ویرایش اطلاعات</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" class="editdata">
            <EditWork
            :unloaded="unloaded"
            :dtitle ="title"
            :dcat-id = "catId"
            :dbrand = "brand"
            :dfixed-number="fixedNumber"
            :dfaxNumber = "faxNumber"
            :dcoTell="coTell"
            :dcoEmail="coEmail"
            :demail = "email"
            :dsite-url="siteUrl"
            :dprovince-id="provinceId"
            :dcity-id="cityId"
            :daddress = "address"
            :dotheraddress = "otherAddress"
            :dotherfax = "otherFaxNumber"
            :dotherfixed = "otherFixedNumber"
            :dothertell="otherTell"
            :dmarkercenter = "marker"

            ></EditWork>
          </div>
        </ContantBlock>
<!--        اطلاعات کاربری-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon2.png"/>
          <span slot="title" >اطلاعات کاربری</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" class="editdata">
            <EditProfile
                :unloaded="unloaded"
                :dtell="tell"
                :dusername="user.username"
                :demail="user.email"


            ></EditProfile>
          </div>
        </ContantBlock>
      </div>
      <div  class="contant-col pr-lg-5 pr-md-4 order-0 order-md-1">
<!--        پرداخت -->
        <template>
          <div v-if="confirmTime>0" class="paid-content mb-2" style="min-height: 15px" >
            <p class="ma-0 primary--text">تایید شده توسط مدیر در تاریخ : {{confirmTime | momentDate}}</p>
          </div>
          <div v-else class="paid-content mb-2" style="min-height: 15px" >
            <p class="ma-0 red--text">این پروفایل هنوز توسط مدیر تایید نشده است. </p>
          </div>
          <WorkPaid v-if="unpaid===true" class="mb-10" ></WorkPaid>
          <div v-if="unpaid===false" class="paid-content mb-10" style="min-height: 15px" >
            <p class="ma-0 primary--text">تعرفه در تاریخ {{payedTime | momentDate}} پرداخت شده و <span style="color:#0a0;">{{premiumTime | momentDays}}</span> روز دیگر اعتبار دارد.</p>
          </div>
        </template>

<!--   معرفی مجموعه-->
        <ContantBlock class="mb-10 d-block  d-md-none" style="min-height: 503px">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon1.png"/>
          <span slot="title" >متن معرفی مجموعه</span>
          <div slot="contant" >
            <WorkIntro :ddesc="desc" :unloaded="unloaded" :class="{'unloaded' : unloaded}"></WorkIntro>
          </div>
        </ContantBlock>



<!--        متن معرفی مجموعه-->
        <ContantBlock class="mb-10 d-none d-md-block" style="min-height: 503px">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon1.png"/>
          <span slot="title" >متن معرفی مجموعه</span>
          <div slot="contant" >
            <WorkIntro :ddesc="desc" :unloaded="unloaded" :class="{'unloaded' : unloaded}"></WorkIntro>
          </div>
        </ContantBlock>

<!--        بارگذاری ویدیو-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon9.png"/>
          <span slot="title" >بارگزاری ویدئو</span>
          <div :class="{'unloaded':unloaded}" slot="contant" >

            <UploadVideo :dvideo="video" :dvideo-url="videoUrl" :unloaded="unloaded" ></UploadVideo>

          </div>
        </ContantBlock>

<!--        خدمات و محصولات-->
        <ContantBlock :class="{'unpaid' : unpaid}" class="mb-10 pb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon3.png"/>
          <span v-if="unpaid" slot="title" >مدیریت خدمات / محصولات <span>(فعالسازی بعداز پرداخت تعرفه)</span></span>
          <span v-else slot="title" >مدیریت خدمات / محصولات</span>
          <div slot="contant" >
            <WorkService :cat-id="catId" :unpaid="unpaid" ></WorkService>
          </div>
        </ContantBlock>


<!--        آگهی ها-->
        <ContantBlock :class="{'unpaid' : unpaid}" class="mb-10 pb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon7.png"/>
          <span v-if="unpaid" slot="title" >مدیریت آگهی‌ها <span>(فعالسازی بعداز پرداخت تعرفه)</span></span>
          <span v-else slot="title" >مدیریت آگهی‌ها</span>
          <div slot="contant" >
            <WorkAds :cat-id="catId" :unpaid="unpaid" ></WorkAds>
          </div>
        </ContantBlock>

<!--        شبکه های اجتماعی-->

        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon6.png"/>
          <span slot="title" >لینک شبکه‌های اجتماعی</span>
          <div :class="{'unloaded':unloaded}" slot="contant" class="editdata" >
            <SocialLinks
                :unloaded="unloaded"
                :dlinkedin="linkedin"
                :dinstagram="instagram"
                :daparat="aparat"
                :dfacebook="facebook"
                :dwhatsApp="whatsApp"
                :dtelegram="telegram"
            ></SocialLinks>
          </div>
        </ContantBlock>


      </div>

    </v-container>
    <v-container v-else class="d-flex flex-wrap flex-md-nowrap pa-0 mt-11">

      <div class="order-1 order-md-0">

        <!--        پرداخت -->
        <template>
          <div v-if="confirmTime>0" class="paid-content mb-2" style="min-height: 15px" >
            <p class="ma-0 primary--text">تایید شده توسط مدیر در تاریخ : {{confirmTime | momentDate}}</p>
          </div>
          <div v-else class="paid-content mb-2" style="min-height: 15px" >
            <p class="ma-0 red--text">این پروفایل هنوز توسط مدیر تایید نشده است. </p>
          </div>
          <WorkPaid v-if="unpaid===true" class="mb-10" ></WorkPaid>
          <div v-if="unpaid===false" class="paid-content mb-10" style="min-height: 15px" >
            <p class="ma-0 primary--text">تعرفه در تاریخ {{payedTime | momentDate}} پرداخت شده و <span style="color:#0a0;">{{premiumTime | momentDays}}</span> روز دیگر اعتبار دارد.</p>
          </div>
        </template>
        <!--   معرفی مجموعه-->
        <ContantBlock class="mb-10 d-block  d-md-none" style="min-height: 503px">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon1.png"/>
          <span slot="title" >متن معرفی مجموعه</span>
          <div slot="contant" >
            <WorkIntro :ddesc="desc" :unloaded="unloaded" :class="{'unloaded' : unloaded}"></WorkIntro>
          </div>
        </ContantBlock>
        <!--        متن معرفی مجموعه-->
        <ContantBlock class="mb-10 d-none d-md-block" style="min-height: 503px">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon1.png"/>
          <span slot="title" >متن معرفی مجموعه</span>
          <div slot="contant" >
            <WorkIntro :ddesc="desc" :unloaded="unloaded" :class="{'unloaded' : unloaded}"></WorkIntro>
          </div>
        </ContantBlock>


        <!--        بارگذاری ویدیو-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon9.png"/>
          <span slot="title" >بارگزاری ویدئو</span>
          <div :class="{'unloaded':unloaded}" slot="contant" >

            <UploadVideo :dvideo="video" :dvideo-url="videoUrl" :unloaded="unloaded" ></UploadVideo>

          </div>
        </ContantBlock>
        <!--        تصاویر -->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon9.png"/>
          <span slot="title" >بارگزاری تصاویر</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" >

            <UploadFiles :unloaded="unloaded" :dlogoimage="logoimage" :dbannerimage="bannerimage" :dpdf="pdf"></UploadFiles>

          </div>
        </ContantBlock>
        <!--        ویرایش اطلاعات-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon2.png"/>
          <span slot="title" >ویرایش اطلاعات</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" class="editdata">
            <EditWork
                    :unloaded="unloaded"
                    :dtitle ="title"
                    :dcat-id = "catId"
                    :dbrand = "brand"
                    :dfixed-number="fixedNumber"
                    :dfaxNumber = "faxNumber"
                    :dcoTell="coTell"
                    :dcoEmail="coEmail"
                    :demail = "email"
                    :dsite-url="siteUrl"
                    :dprovince-id="provinceId"
                    :dcity-id="cityId"
                    :daddress = "address"
                    :dotheraddress = "otherAddress"
                    :dotherfax = "otherFaxNumber"
                    :dotherfixed = "otherFixedNumber"
                    :dothertell="otherTell"
                    :dmarkercenter = "marker"

            ></EditWork>
          </div>
        </ContantBlock>
        <!--        اطلاعات کاربری-->
        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon2.png"/>
          <span slot="title" >اطلاعات کاربری</span>
          <div :class="{'unloaded' : unloaded}" slot="contant" class="editdata">
            <EditProfile
                    :unloaded="unloaded"
                    :dtell="tell"
                    :dusername="user.username"
                    :demail="user.email"


            ></EditProfile>
          </div>
        </ContantBlock>


        <!--        شبکه های اجتماعی-->

        <ContantBlock class="mb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon6.png"/>
          <span slot="title" >لینک شبکه‌های اجتماعی</span>
          <div :class="{'unloaded':unloaded}" slot="contant" class="editdata" >
            <SocialLinks
                    :unloaded="unloaded"
                    :dlinkedin="linkedin"
                    :dinstagram="instagram"
                    :daparat="aparat"
                    :dfacebook="facebook"
                    :dwhatsApp="whatsApp"
                    :dtelegram="telegram"
            ></SocialLinks>
          </div>
        </ContantBlock>


        <!--        خدمات و محصولات-->
        <ContantBlock :class="{'unpaid' : unpaid}" class="mb-10 pb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon3.png"/>
          <span v-if="unpaid" slot="title" >مدیریت خدمات / محصولات <span>(فعالسازی بعداز پرداخت تعرفه)</span></span>
          <span v-else slot="title" >مدیریت خدمات / محصولات</span>
          <div slot="contant" >
            <WorkService :cat-id="catId" :unpaid="unpaid" ></WorkService>
          </div>
        </ContantBlock>


        <!--        آگهی ها-->
        <ContantBlock :class="{'unpaid' : unpaid}" class="mb-10 pb-10">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon7.png"/>
          <span v-if="unpaid" slot="title" >مدیریت آگهی‌ها <span>(فعالسازی بعداز پرداخت تعرفه)</span></span>
          <span v-else slot="title" >مدیریت آگهی‌ها</span>
          <div slot="contant" >
            <WorkAds :cat-id="catId" :unpaid="unpaid" ></WorkAds>
          </div>
        </ContantBlock>




      </div>

    </v-container>




  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ContantBlock from "@/components/ContantBlock";
import UploadFiles from "@/components/Profile/UploadFiles";
import UploadVideo from "@/components/Profile/UploadVideo";
import EditWork from "@/components/Profile/EditWork";
import WorkIntro from "@/components/Profile/WorkIntro";
import SocialLinks from "@/components/Profile/SocialLinks";
import WorkService from "@/components/Profile/WorkService";
import WorkAds from "@/components/Profile/WorkAds";
import WorkPaid from "../components/Profile/WorkPaid";
import EditProfile from "../components/Profile/EditProfile";
import moment from "jalali-moment";
import axios from "axios";
export default {
  name: 'App',

  data() {
    return {
      work : null,


      logoimage : '',
      bannerimage : '',
      pdf : '',
      video : '',
      videoUrl:'',
      desc : '',
      title : '',
      catId : '',
      brand : '',
      fixedNumber : '',
      email : '',
      coEmail : '',
      coTell : '',
      faxNumber : '',
      whatsApp : '',
      siteUrl : '',
      provinceId : '',
      cityId : '',
      address : '',
      linkedin : '',
      instagram : '',
      facebook : '',
      aparat : '',
      youtube : '',
      telegram : '',
      otherTell : [],
      otherAddress : [],
      otherFaxNumber : [],
      otherFixedNumber : [],
      unpaid : true,
      premiumTime : '',
      confirmTime : '',
      payedTime : '',
      tell : '',
      user : {},
      unloaded : true,
      marker : null,
    }
  },
  components : {
    BreadCrumbs,
    ContantBlock,
    UploadFiles,
    UploadVideo,
    EditWork,
    EditProfile,
    WorkIntro,
    SocialLinks,
    WorkService,
    WorkAds,
    WorkPaid
  },
  created() {
    axios.post(this.$store.state.api + 'getwork', {
        userId : this.$store.state.userId,
    }).then(response => {
      this.work = response.data.data;
      this.unloaded = false;
      this.premiumTime = this.work.premiumTime;
      if (this.momentRel(this.premiumTime)<0){
        this.unpaid = true;
      } else {
        this.unpaid = false;
      }
      this.payedTime = this.work.payedTime;
      this.confirmTime = this.work.confirmTime;
      this.logoimage = this.work.image;
      this.bannerimage = this.work.banner;
      this.pdf = this.work.cvImage;
      this.video = this.work.video;
      this.videoUrl = this.work.videoUrl;
      this.desc = this.work.desc;
      this.title = this.work.title;
      this.catId = this.work.catId;
      this.brand = this.work.brand;
      this.fixedNumber = this.work.fixedNumber;
      this.email = this.work.email;
      this.siteUrl = this.work.siteUrl;
      this.provinceId = this.work.provinceId;
      this.cityId = this.work.cityId;
      this.address = this.work.address;
      this.linkedin = this.work.linkedin;
      this.instagram = this.work.instagram;
      this.facebook = this.work.facebook;
      this.aparat = this.work.aparat;
      this.whatsApp = this.work.whatsApp;
      this.telegram = this.work.telegram;
      this.coTell = this.work.coTell;
      this.coEmail = this.work.coEmail;
      this.faxNumber = this.work.faxNumber;
      this.tell = this.work.tell;
      this.user = this.work.user;
      this.otherTell = JSON.parse(this.work.otherTell);
      this.otherAddress = JSON.parse(this.work.otherAddress);
      this.otherFaxNumber = JSON.parse(this.work.otherFaxNumber);
      this.otherFixedNumber = JSON.parse(this.work.otherFixedNumber);
      if(this.work.long.length>0){
        this.marker = [this.work.long,this.work.lat];
      }
      else {
        this.marker = null;
      }




    })
  },
  filters : {
  momentDays(date){
    var a = moment(new Date());
    var b = moment.unix(date);
    return b.diff(a, 'days');
  },
    momentDate(date){
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
    },
  },
  methods : {
    momentRel(date){
      var a = moment(new Date());
      var b = moment.unix(date);
      return b.diff(a, 'hours') + 1;
    },
  },
  metaInfo() {
    return {
      title:'پروفایل',
      link: [{rel: 'canonical', href: this.$store.state.url+'/profile'}]
    }
  },
};
</script>


<style lang="scss">

.profile .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: #570654 !important;
  color: white !important;
}

.abouttxt .v-input {
  font-size: 16px !important;
}

.abouttxt .v-input * {
  color: #570654 !important;
}

.abouttxt .v-label{
  color: #a3a3a3 !important;
}

.abouttxt .v-label--active{
  color: #570654 !important;
}

.abouttxt .v-text-field fieldset{
  border-color: #e8e8e8 ;
}

.editdata .v-input *{
  color: #000 !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.editdata .address .v-input *{
  font-size: 14px !important;
}

.editdata .v-text-field fieldset{
  border-color: #e8e8e8 ;
}

.editdata .v-input--is-focused fieldset{
  border-color: #570654 !important;
}

.editdata .v-label{
  color: #a3a3a3 !important;
}

.editdata .v-input__slot{
  margin-bottom: 4px !important;
}

.editdata .v-text-field__details{
  margin-bottom: 4px !important;
}

.editdata .v-list * , ::before , ::after {
  color: #570654 !important;
}

.editdata .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f6f6f6;
}

.editdata .v-select .v-input__append-inner{
  margin-top: 4px !important;
}

.editdata .v-select .v-input__slot{
  background-color: #f6f6f6 !important;
}

.editdata .v-select .v-label{
  color: #000 !important;
}

.editdata .v-select .v-input__slot {
  box-shadow: none !important;
  border : 1px solid #e8e8e8;
}

.editdata .socialdata .v-input__slot {
  padding-right: 4px !important;
}

.editdata .socialdata .v-input * {
  font-size: 14px !important;
  text-align: left !important;
}

.headertable1{
  min-height: 60px;
  width: 100%;
  color: #000 ;
  font-size: 14px ;
  background-color: #dadada;
}

.table1 {
  width: 100%;
  min-height: 60px;
  color: #000 ;
  font-size: 14px ;
  border-bottom: 1px solid #ededed;
}

table * {
  font-weight: normal ;
  color: black ;
  font-size: 14px ;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  text-align: right;
  padding: 8px;
}

tr:nth-child(1){
  background-color: #dadada;
}

tr {
  background-color: #fcfcfc;
  border-bottom: 1px solid #dadada;
}

.unpaid{
  opacity: 0.5;
  user-select: none;
}

.unloaded{
  opacity: 0.5;
  user-select: none;
}

</style>


<style>
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style>
.paid-content{
  background-color: #f3f3f3;
  padding: 16px 24px;
  min-height: 250px;
  color: #575757;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
}
</style>