<template>
  <div>
    <div v-if="role=='address'">
      <p class="mb-2" style="font-size: 14px">آدرس / نشانی {{indexnumber+2}} :</p>
      <div class="address mb-2">
        <v-textarea
            background-color="white"
            outlined
            solo
            v-model="field"
            color="#570654"
            class="rounded-0 "
        ></v-textarea>
    </div>
    </div>
    <div v-else>
      <p class="mb-2" style="font-size: 14px">شماره {{fieldname}} {{indexnumber+2}} : </p>
      <v-text-field
          background-color="white"
          height="50"
          outlined
          v-model="field"
          style="direction: ltr"
          solo
          color="#570654"
          class="rounded-0 "
      ></v-text-field>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  props : {
    role : null,
    indexnumber : null,
    fieldname : null,
    loadedfield : null,
  },

  data() {
    return {
      field : this.loadedfield[this.indexnumber],
    }
  },
  watch : {
    field(){
      let data = [this.role,this.indexnumber,this.field];
      this.$emit('fieldevent',data)
    },
    loadedfield(){
      this.field = this.loadedfield[this.indexnumber];
    }
  }
};
</script>
