<template>
  <div >
    <div class="abouttxt">

      <v-textarea
          background-color="white"
          label="معرفی مجموعه"
          outlined
          :disabled="unloaded"
          :value="desc"
          v-model="desc"
          rows="9"
          @keypress="isSpace($event)"
          color="#570654"
          hide-details
          class="rounded-0"
      ></v-textarea>
      <div class="v-text-field__details mt-1 ml-3"><div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div><div class="v-counter theme--light primary--text">{{spaces}} / 1500</div></div>
    </div>


    <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>
    <v-btn
        @click="saveintro"
        class="btn d-block mr-auto mb-1 ml-1"
        :loading="updateloading"
        :disabled="unloaded"
    >
      ذخیره و بروزرسانی
    </v-btn>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  props : {
    ddesc : null,
    unloaded : {
      default : true,
    }
  },
  data() {
    return {
      updateloading : false,
      updatenotice : false,
      spaces : 0,
      desc : null,
    }
  },
  methods : {
    isSpace: function(evt) {

        if(this.spaces===1500){
          evt.preventDefault();
        }


    },
    saveintro(){
      this.updateloading = true;
      axios.post(this.$store.state.api + 'setwork', {
        userId : this.$store.state.userId,
        desc : this.desc,
      }).then(response => {
        if(response.data.result){
          this.updatenotice = true;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'بروزرسانی شد';
          this.updateloading = false;
        }
      })
    }
  },
  watch : {
    ddesc(){
      this.desc = this.ddesc;
    },
    desc(){
        this.spaces = 0;
        for (let i in this.desc){
          if (this.desc.charAt(i) === " ") { // if a space is found in str
            this.spaces++; // add 1 to total so far
          }
          if(this.spaces>1500){
            this.desc = this.desc.substring(0,i);
          }
        }
    }
  }
};
</script>

<style lang="scss">



.abouttxt .v-input--is-focused fieldset {
  border-color: #570654 !important;
}

</style>
