<template>
  <div>
    <v-dialog
        v-model="showimage"
        max-width="500"
    >
      <v-img contain :src="$store.state.upload + selectedimage" width="100%" max-height="500px" style="background-color: grey">
        <template v-slot:default>
          <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
            <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
          </svg>
        </template>

      </v-img>
    </v-dialog>
    <v-progress-linear
        v-if="logoprogress"
        :value="logoprogress"
    >
    </v-progress-linear>
    <div class="d-flex align-center" >
    <div class="upbox" style="width: 100%">
      <svg v-if="logoimage" :class="{'animate__animated animate__flash':uploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
      </svg>
      <p v-if="logoimage" @click="showimages(logoimage)" class="d-flex align-center ma-0 primary--text" style="font-size: 14px;cursor: pointer"><img @click="showimage=true" class="ml-1" style="cursor: pointer" height="35" :src="$store.state.smallthumbnail + this.logoimage"/>لوگو<span style="font-size: 12px"> (Logo{{logoimageformat}}) </span></p>
      <p v-else class="ma-0" style="font-size: 14px">لوگو</p>


      <v-btn
          height="32"
          v-if="!logoimage"
          style="font-size: 10px !important;"
          :disabled="unloaded"
          class="btn my-1 mr-auto px-2"
      >
        <template v-slot:default>

          <label style="cursor: pointer ; height: 32px;display: flex;align-items: center;">
            <input type="file" ref="logofile" @change="uploadlogo" accept="image/*" style="display: none">
            انتخاب و آپلود فایل
          </label>

        </template>
      </v-btn>
    </div>
    <span @click="logoimage = null" style="cursor: pointer" >
      <v-icon v-if="logoimage" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
        </span>
    </div>
    <p class="primary--text mt-1 mb-5" style="font-size: 10px">راهنما : تصویر در سایز 500 پیکسل در 500 پیکسل آپلود نمائید.</p>
    <v-progress-linear
        v-if="bannerprogress"
        :value="bannerprogress"
    >
    </v-progress-linear>
    <div class="d-flex align-center" >
    <div class="upbox" style="width: 100%">
      <svg v-if="bannerimage" :class="{'animate__animated animate__flash':banneruploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
      </svg>
      <p v-if="bannerimage" @click="showimages(bannerimage)" class="d-flex align-center ma-0 primary--text" style="font-size: 14px;cursor: pointer"><img @click="showimage=true" class="ml-1" style="cursor: pointer" height="35" :src="$store.state.smallthumbnail + this.bannerimage"/>بنر<span style="font-size: 12px"> (Banner{{bannerimageformat}}) </span></p>
      <p v-else class="ma-0" style="font-size: 14px">تصویر بنر</p>


      <v-btn
          height="32"
          style="font-size: 10px !important;"
          v-if="!bannerimage"
          :disabled="unloaded"
          class="btn my-1 mr-auto px-2"
      >
        <template v-slot:default>

          <label style="cursor: pointer ; height: 32px;display: flex;align-items: center">
            <input type="file" ref="bannerfile" @change="uploadbanner" accept="image/*" style="display: none">
            انتخاب و آپلود فایل
          </label>

        </template>
      </v-btn>
    </div>
    <span @click="bannerimage = null" style="cursor: pointer" >
      <v-icon v-if="bannerimage" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
        </span>
    </div>

    <p class="primary--text mt-1 mb-5" style="font-size: 10px">راهنما : تصویر در سایز 1600 پیکسل در 500 پیکسل آپلود نمائید.</p>


    <v-progress-linear
        v-if="pdfprogress"
        :value="pdfprogress"
    >
    </v-progress-linear>
    <div class="d-flex align-center">
    <div class="upbox" style="width: 100%;">
      <svg v-if="pdf" :class="{'animate__animated animate__flash':pdfuploading}" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#570654" d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" />
      </svg>
      <p v-if="pdf" class="ma-0 primary--text" style="font-size: 14px;cursor: pointer">رزومه<a :href="$store.state.upload+pdf" style="font-size: 12px;cursor: pointer;text-decoration: none"> (CV{{pdfformat}}) </a></p>
      <p v-else class="ma-0" style="font-size: 14px">فایل رزومه</p>


      <v-btn
          height="32"
          style="font-size: 10px !important;"
          v-if="!pdf"
          :disabled="unloaded"
          class="btn my-1 mr-auto px-2"
      >
        <template v-slot:default>

          <label style="cursor: pointer ; height: 32px;display: flex;align-items: center">
            <input type="file" ref="pdffile" @change="uploadpdf" accept=".pdf" style="display: none">
            انتخاب و آپلود فایل
          </label>

        </template>
      </v-btn>
    </div>
      <span @click="pdf = null" style="cursor: pointer" >
      <v-icon v-if="pdf" class="ma-0 mr-2 red--text">mdi-delete</v-icon>
        </span>
    </div>
    <p class="primary--text mt-1 mb-5" style="font-size: 10px">راهنما : فایل با پسورد pdf آپلود نمائید.</p>

      <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>


      <v-btn
        class="btn d-block mr-auto mb-1 ml-3"
        @click="saveimages"
        :disabled="unloaded"
        :loading="updateloading"
    >
      ذخیره و بروزرسانی
    </v-btn>



  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'App',
  components : {
  },
  props : {
    dlogoimage : null,
    dbannerimage : null,
    dpdf : null,
    unloaded : {
      default : true,
    }
  },

  data() {
    return {
      logoimage : null,
      bannerimage : null,
      pdf : null,
      logoprogress : 0,
      bannerprogress : 0,
      pdfprogress : 0,
      uploading : false,
      pdfuploading : false,
      banneruploading : false,
      showimage : false,
      selectedimage : null,
      updateloading : false,
      logoimageformat : null,
      bannerimageformat : null,
      pdfformat:null,
      updatenotice : false,
    }
  },
  watch : {

    dlogoimage(){
        this.logoimage = this.dlogoimage;
    },

    dbannerimage(){
        this.bannerimage = this.dbannerimage;
    },

    dpdf(){
        this.pdf = this.dpdf;
    },

    logoimage(newval,oldval){
      if(newval!=oldval){
        this.logoimageformat = this.logoimage.substring(this.logoimage.length-4, this.logoimage.length);
      }
    },
    bannerimage(newval,oldval){
      if(newval!=oldval){
        this.bannerimageformat = this.bannerimage.substring(this.bannerimage.length-4, this.bannerimage.length);
      }
    },
    pdf(newval,oldval){
      if(newval!=oldval){
        this.pdfformat = this.pdf.substring(this.pdf.length-4, this.pdf.length);
      }
    },
  },
  methods : {

    showimages(image){
      this.selectedimage = image;
      this.showimage = true;
    },

    uploadlogo: function () {
      this.logoimage = '';
      this.logofile = this.$refs.logofile.files[0];
      let formData = new FormData();
      formData.append('file', this.logofile);
      axios.post(this.$store.state.api+'imageupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.logoprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.uploading = true;
              this.logoimage = response.data.data;
              this.logoprogress = 0;
            }
          }
      ).catch(()=>{
        this.logoprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    },
    uploadbanner: function () {
      this.bannerimage = '';
      this.bannerfile = this.$refs.bannerfile.files[0];
      let formData = new FormData();
      formData.append('file', this.bannerfile);
      axios.post(this.$store.state.api+'imageupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.bannerprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.banneruploading=true;
              this.bannerimage = response.data.data;
              this.bannerprogress = 0;
            }
          }
      ).catch(()=>{
        this.bannerprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    },
    uploadpdf: function () {
      this.pdf = '';
      this.pdffile = this.$refs.pdffile.files[0];
      let formData = new FormData();
      formData.append('file', this.pdffile);
      axios.post(this.$store.state.api+'fileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
          this.pdfprogress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }.bind(this)
      }).then(response => {
            if (response.data.result) {
              this.pdfuploading=true;
              this.pdf = response.data.data;
              this.pdfprogress = 0;
            }
          }
      ).catch(()=>{
        this.pdfprogress = 0;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'آپلود ناموفق بود';
      });
    },
    saveimages(){
      this.updateloading=true;
      axios.post(this.$store.state.api + 'setwork', {
        userId : this.$store.state.userId,
        image : this.logoimage,
        banner : this.bannerimage,
        cvImage : this.pdf,
      }).then(response => {
        if(response.data.result){
          this.updatenotice = true;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'بروزرسانی شد';
          this.updateloading=false;
        }
      })
    }
  }
};
</script>


<style>
@import "~animate.css";

.upbox{
  min-height: 50px;
  font-size: 14px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  color: #a3a3a3;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  padding:0px 8px;
  user-select: none;
}

</style>
