<template>
  <div>
    <div class="d-flex justify-center align-center flex-wrap justify-sm-space-between">
    <p class="ma-0 primary--text mb-7 text-center" style="font-size: 24px">پرداخت تعرفه</p>
    <div class="editwork paidwork--width d-inline-block">
    <v-select
        label="انتخاب تعرفه"
        solo
        return-object
        v-model="price"
        :items="prices"
        :error-messages="priceserror"
        item-text="title"
        @input="$v.price.$touch()"
        @blur="$v.price.$touch()"
        height="52"
        class="rounded-0"
    >
      <template v-slot:append>
        <img src="../../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
      </template>
    </v-select>
    </div>
      <div class="mb-3" style="width: 173px;text-align: center">
        <p class="mb-0" style="font-size: 20px;color:#06b22a;" v-if="price.price" >{{ price.price | numFormat }} <span style="color: #777777">تومان</span></p>
<!--
        <p style="font-size: 20px;color:#06b22a;" v-else ><span style="color: #777777">قیمت تعرفه</span></p>
-->
        <small class="primary--text" v-if="price && price.price" >{{ price.price.num2persian() }}</small>
      </div>
      <v-btn
          v-if="!price.price"
          class="btn d-block mb-7"
          style="padding-top: 14px"
          :style="{'background-color':'#c0c0c0 !important'}"
          height="55"
          :disabled="!price.id"
          :href="'https://server.iranicib.com/api/payment?userId='+$store.state.userId+'&priceId='+price.id+'&disId='+this.percentId"
      >
        <span style="font-size: 18px" >پرداخت آنلاین</span>
      </v-btn>
    </div>

    <div class="d-flex align-center justify-space-between flex-wrap">
<!--
    <p @click="disShow = !disShow" :class="{'mt-md-5 mb-md-9' : !disShow}" class="ml-2" style="cursor: pointer;color: #2e2e2e;font-size: 20px;user-select: none">کد <span style="color: darkred">تخفیف</span> دارید ؟</p>
-->
      <div class=" discount d-inline-block paidwork--width">
        <div class="d-flex align-center" >
          <v-text-field
              v-if="price.id"
              background-color="white"
              height="36"
              label="کد تخفیف"
              outlined
              :success-messages="successDiscount"
              :error-messages = "errorDiscount"
              v-model="discount"
              solo
              color="#570654"
              class="rounded-0 rounded-l-lg mx-auto"
          >
            <template v-slot:append>
              <v-btn
                  @click="discountCheck()"
                  class="btn rounded-lg"
                  height="55"
              >
                <span style="font-size: 14px" >بررسی کد</span>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </div>


      <div v-if="percent" class="mb-8 paidwork--width mr-md-6" style="text-align: center" >
        <p class="mb-0" style="color:#4f4f4f;font-size: 14px;" v-if="price && percent" >قیمت نهایی</p>
        <small class="mb-0" style="color:#06b22a;" v-if="price && percent" >{{ price.price * ((100-percent)/100) | numFormat }} <span style="color: #777777;">تومان</span></small>
      </div>
      <div v-else-if="price.price" class="mb-8 paidwork--width mr-md-6" style="text-align: center" >
        <p class="mb-0" style="color:#4f4f4f;font-size: 14px;" v-if="price" >قیمت نهایی</p>
        <small class="mb-0" style="color:#06b22a;" v-if="price" >{{ price.price | numFormat }} <span style="color: #777777;">تومان</span></small>
      </div>




      <v-btn
          class="btn d-block mr-auto mb-7"
          style="padding-top: 14px"
          height="55"
          v-if="price.price && percentId"
          :disabled="!price.id"
          :href="'https://server.iranicib.com/api/payment?userId='+$store.state.userId+'&priceId='+price.id+'&disId='+this.percentId"
      >
        <span style="font-size: 18px" >پرداخت آنلاین</span>
      </v-btn>
      <v-btn
          class="btn d-block mr-auto mb-7"
          style="padding-top: 14px"
          height="55"
          v-if="price.price && !percentId"
          :disabled="!price.id"
          :href="'https://server.iranicib.com/api/payment?userId='+$store.state.userId+'&priceId='+price.id"
      >
        <span style="font-size: 18px" >پرداخت آنلاین</span>
      </v-btn>
    </div>

    <div class="paid-content">

      <p class="mb-2 primary--text font-weight-bold" style="font-size: 24px">پرداخت آفلاین</p>
      <div class="mb-10 primary--text" v-html="txt"></div>
      <div v-if="prices.length<1">
      <p v-for="n in 4" :key="n" class="mb-2 primary--text">......................................</p>
      </div>
      <div v-else>
      <p v-for="price in prices" :key="price.id" class="mb-2 primary--text">تعرفه {{price.title}} {{price.price | numFormat}} تومان</p>
      </div>

    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import axios from "axios";
import numeral from 'numeral';
import Num2persian from 'num2persian';
// eslint-disable-next-line no-unused-vars
let a = Num2persian(0);

import numFormat from 'vue-filter-number-format';
import { validationMixin } from 'vuelidate'
import {required} from "vuelidate/lib/validators";


Vue.filter('numFormat', numFormat(numeral));

export default {
  name: 'App',
  mixins : [validationMixin],
  validations: {

    price : {
      required
    }

  },
  data() {
    return {
      prices : [],
      price : {
        price : null,
      },
      txt : null,
/*      disShow : false,*/
      discount : null,
      percent : 0,
      percentId : null,
      successDiscount : '',
      errorDiscount : ''
    }
  },
  created() {
    axios.get(this.$store.state.api + 'prices').then(response=>{
      this.prices = response.data.data;
    })

    axios.get(this.$store.state.api + 'offlinepay').then(response=>{
      this.txt = response.data.data;
    });
  },
  methods : {
    discountCheck(){
      axios.post(this.$store.state.api + 'discount',{
        userId : this.$store.state.userId,
        code : this.discount,
      }).then(response=>{
        if(response.data.result){
          this.percent = response.data.data.percent;
          this.percentId = response.data.data.id;
          this.$store.state.notify.is=true;
          this.$store.state.notify.text = response.data.message;
          this.successDiscount = 'کد تخفیف ' + this.percent + '  درصدی اعمال شد';
          this.errorDiscount = '';
        } else {
          this.percent = null;
          this.percentId = null;
          this.$store.state.notify.is=true;
          this.$store.state.notify.text = response.data.message;
          this.successDiscount = '';
          this.errorDiscount = response.data.message;
        }
      })
    },
    /*payment(id){
      this.$v.$touch();
      if(id && !this.$v.$invalid){
        window.location.href = this.$store.state.api + 'payment?priceId='+this.price+'&userId='+this.$store.state.userId;
      }
    },*/
    /*signup(){

      if (this.$v.$invalid || !this.numberValidate) {
        console.log('Err');
      } else {
        console.log('ok');
      }
    }*/
  },
  computed :{
    priceserror(){
      const errors = []
      if (!this.$v.price.$dirty) return errors
      !this.$v.price.required && errors.push('تعرفه خود را تعیین کنید.')
      return errors
    }
  }
};
</script>


<style>
.paid-content{
  background-color: #f3f3f3;
  padding: 16px 24px;
  min-height: 250px;
  color: #575757;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
}
</style>

<style lang="scss" >


.paidwork--width {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .paidwork--width {
    width: 220px;
  }
}



.editwork .error--text fieldset {
  border-color: #570654 !important;
}

.editwork .v-text-field .v-text-field__details *{
  font-size: 12px !important;
  color: #570654 !important;
}

.editwork .address .v-text-field .v-text-field__details *{
  font-size: 16px !important;
}


.editwork .v-list * , ::before , ::after {
  color: #570654 !important;
  /*font-size: 16px !important;*/
}

.editwork .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f6f6f6;
}

.editwork .v-select .v-input__append-inner{
  margin-top: 4px !important;
}

.editwork .v-select .v-input__slot{
  background-color: #f6f6f6 !important;
}

.editwork .v-select .v-label{
  color: #000 !important;
}

.editwork .v-select .v-input__slot {
  box-shadow: none !important;
  border : 1px solid #e8e8e8;
}

.editwork .socialdata .v-input__slot {
  padding-right: 4px !important;
}

.editwork .socialdata .v-input * {
  font-size: 16px !important;
}

.discount .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
   padding: 0 12px 0 0 !important;
}

</style>
