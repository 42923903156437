<template>
  <div>
    <div class="socialdata">
      <div class="d-flex justify-space-between flex-wrap flex-md-nowrap">
      <div class="socialfield">
      <v-text-field
          background-color="white"
          height="58"
          outlined
          :disabled="unloaded"
          v-model="linkedin"
          solo
          style="direction: ltr"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/linkedin.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >linkedin.com/</p>
        </template>

      </v-text-field>
      </div>

        <div class="socialfield">
      <v-text-field
          background-color="white"
          height="58"
          outlined
          :disabled="unloaded"
          v-model="instagram"
          solo
          style="direction: ltr"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/instagam.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >instagram.com/</p>
        </template>

      </v-text-field>
        </div>

      </div>

      <div class="d-flex justify-space-between flex-wrap flex-md-nowrap">

        <div class="socialfield">

      <v-text-field
          background-color="white"
          height="58"
          outlined
          :disabled="unloaded"
          v-model="facebook"
          solo
          style="direction: ltr"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/facebook.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >facebook.com/</p>
        </template>

      </v-text-field>
        </div>


      <div class="socialfield">
      <v-text-field
          background-color="white"
          height="58"
          outlined
          :disabled="unloaded"
          v-model="aparat"
          solo
          style="direction: ltr"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/aprat.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >aparat.com/</p>
        </template>

      </v-text-field>
      </div>
      </div>


      <div class="d-flex justify-space-between flex-wrap flex-md-nowrap">
      <div class="socialfield">
      <v-text-field
          background-color="white"
          height="58"
          outlined
          solo
          style="direction: ltr"
          :disabled="unloaded"
          v-model="whatsApp"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/whatsapp.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >https://wa.me/</p>
        </template>

      </v-text-field>
      </div>

      <div class="socialfield">
      <v-text-field
          background-color="white"
          height="58"
          outlined
          solo
          style="direction: ltr"
          :disabled="unloaded"
          v-model="telegram"
          color="#570654"
          class="rounded-0 "
      >
        <template v-slot:append>
          <img src="../../assets/image/Public Icons/Social/telegram.png" height="50" width="50"/>
        </template>

        <template v-slot:prepend-inner>
          <p class="ma-0" >https://t.me/</p>
        </template>

      </v-text-field>
      </div>
      </div>

    </div>

    <p class="mb-2 primary--text animate__animated animate__flash" v-if="updatenotice" style="font-size: 12px" >اطلاعات شما پس از بررسی ، تائید خواهد گردید</p>
    <v-btn
        class="btn d-block mr-auto mb-1 ml-1"
        @click="savesociallinks"
        :disabled="unloaded"
        :loading="updateloading"
    >
      ذخیره و بروزرسانی
    </v-btn>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  props : {
    dlinkedin : null,
    dinstagram : null,
    dfacebook : null,
    daparat : null,
    dwhatsApp : null,
    dtelegram : null,
    unloaded : {
      default : true,
    }
  },
  data() {
    return {
      updateloading : false,
      updatenotice : false,

      linkedin : null,
      instagram : null,
      facebook : null,
      aparat : null,
      whatsApp : null,
      telegram : null,
    }
  },
  methods : {
    savesociallinks(){
      this.updateloading = true;
      axios.post(this.$store.state.api + 'setwork', {
        userId : this.$store.state.userId,
        linkedin : this.linkedin,
        instagram : this.instagram,
        facebook : this.facebook,
        aparat : this.aparat,
        whatsApp : this.whatsApp,
        telegram : this.telegram,
      }).then(response => {
        if(response.data.result){
          this.updatenotice = true;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'بروزرسانی شد';
          this.updateloading = false;
        }
      })
    }
  },
  watch : {
    dlinkedin(){
      this.linkedin = this.dlinkedin;
    },
    dinstagram(){
      this.instagram = this.dinstagram;
    },
    dfacebook(){
      this.facebook = this.dfacebook;
    },
    daparat(){
      this.aparat = this.daparat;
    },
    dwhatsApp(){
      this.whatsApp = this.dwhatsApp;
    },
    dtelegram(){
      this.telegram = this.dtelegram;
    },
  }
};
</script>

<style lang="scss" >
.socialfield{
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .socialfield{
    width: 310px;
  }


}
</style>
